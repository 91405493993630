import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { AllStrapiTestimonial, QueryResult, StrapiPage } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import Testimonials from '~/views/Testimonials/Testimonials.view'

export type TestimonialsPageProps = QueryResult<
  StrapiPage & AllStrapiTestimonial
>

const TestimonialsPage = ({ data }: TestimonialsPageProps) => (
  <Testimonials data={data} />
)
export default TestimonialsPage

export const query = graphql`
  query {
    strapiPage(page_name: { eq: "Testimonials" }) {
      ...MetaTagInformationPages
    }

    allStrapiTestimonial(sort: { rating_number: ASC }) {
      edges {
        node {
          id
          name
          link
          position
          testimonial {
            data {
              testimonial
            }
          }
          limit_paragraphs
          rating_number
          video_link
          videoPreview {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
              }
            }
          }
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
              }
            }
          }
          flag {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
              }
            }
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`

export const Head = ({ data }: TestimonialsPageProps) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url="https://www.codica.com/testimonials/"
    />
  )
}
