import { Link } from 'gatsby'

import LazyHydrate from '~/components/shared/LazyHydrate'
import { TestimonialsPageProps } from '~/pages/testimonials'
import TestimonialContent from '~/views/Testimonials/components/TestimonialContent'
import TestimonialReviewer from '~/views/Testimonials/components/TestimonialReviewer'
import TestimonialVideo from '~/views/Testimonials/components/TestimonialVideo'

import * as containerStyles from './TestimonialPost.module.scss'

const TestimonialPost = ({ data }: TestimonialsPageProps) => (
  <div className={containerStyles.testimonialCard}>
    <div className="container container-md">
      <div className="row">
        {data.allStrapiTestimonial.edges.map(({ node: post }) => (
          <div className="col-12" key={post.id}>
            <div className={containerStyles.testimonialCard__wrapper}>
              <TestimonialReviewer post={post} />
              <TestimonialContent post={post} />
              {post.link && (
                <Link
                  to={`/case-studies/${post.link}/`}
                  className={containerStyles.testimonialCard__postLinkBtn}
                >
                  View case study
                </Link>
              )}
              {post.video_link && (
                <LazyHydrate whenVisible>
                  <TestimonialVideo post={post} />
                </LazyHydrate>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default TestimonialPost
