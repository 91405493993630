import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'

import Button from '~/components/buttons/Button'
import { fileToImageLikeData } from '~/utils'

import * as containerStyles from './TestimonialVideo.module.scss'

const TestimonialVideo = ({ post }) => {
  const [showVideo, setShowVideo] = useState(false)

  const handleChange = () => {
    setShowVideo(() => true)
  }

  return (
    <div className={containerStyles.testimonialCard__video}>
      {!showVideo && (
        <div className={containerStyles.testimonialCard__videoPreview}>
          {React.isValidElement(post.videoPreview.localFile) ? (
            post.videoPreview.localFile
          ) : (
            <GatsbyImage
              image={
                getImage(
                  fileToImageLikeData(post.videoPreview.localFile),
                ) as IGatsbyImageData
              }
              alt="Client review | Codica"
              title="Client review for Codica"
              loading="lazy"
            />
          )}
          <Button
            type="button"
            name="Play video"
            onClick={handleChange}
            onKeyDown={handleChange}
            customClassName={containerStyles.testimonialCard__playVideo}
          />
        </div>
      )}
      <div className={containerStyles.testimonialCard__videoYoutube}>
        {showVideo && (
          <iframe
            src={post.video_link}
            title="Client video review"
            width="100%"
            height="100%"
            loading="lazy"
            allowFullScreen
          />
        )}
      </div>
    </div>
  )
}

export default TestimonialVideo
