import { useState } from 'react'

import Button from '~/components/buttons/Button'

import * as containerStyles from './TestimonialContent.module.scss'

const TestimonialContent = ({ post }) => {
  const [showMore, setShowMore] = useState(false)
  const paragraphs = post.limit_paragraphs || 3

  const handleShowMore = () => {
    setShowMore(() => true)
  }

  return (
    <div className={containerStyles.testimonialCard__post}>
      {showMore
        ? post.testimonial.data.testimonial
            .split('\n\n')
            .map((paragraph) => <p key={paragraph}>{paragraph}</p>)
        : post.testimonial.data.testimonial
            .split('\n\n')
            .slice(0, paragraphs)
            .map((paragraph) => <p key={paragraph}>{paragraph}</p>)}

      {post.limit_paragraphs &&
        post.testimonial.data.testimonial.split('\n\n').length > paragraphs &&
        !showMore && (
          <Button
            type="button"
            name="Show more"
            onClick={handleShowMore}
            onKeyDown={handleShowMore}
            customClassName={containerStyles.testimonialCard__showMore}
          />
        )}
    </div>
  )
}

export default TestimonialContent
