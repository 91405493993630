import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

import { fileToImageLikeData } from '~/utils'

import * as containerStyles from './TestimonialReviewer.module.scss'

const TestimonialReviewer = ({ post }) => (
  <div className={containerStyles.testimonialCard__reviewerWrapper}>
    <div className={containerStyles.testimonialCard__reviewerInfo}>
      {post.photo && (
        <div className={containerStyles.testimonialCard__photo}>
          {React.isValidElement(post.photo.localFile) ? (
            post.photo.localFile
          ) : (
            <GatsbyImage
              image={
                getImage(
                  fileToImageLikeData(post.photo.localFile),
                ) as IGatsbyImageData
              }
              alt={`${post.name} ${post.position} | Codica`}
              title={`${post.name} ${post.position}`}
              loading="lazy"
            />
          )}
        </div>
      )}
      <div>
        <div className="d-flex align-items-center mb-2">
          {post.name && (
            <div className={containerStyles.testimonialCard__name}>
              {post.name}
            </div>
          )}
          <div className={containerStyles.testimonialCard__flag}>
            {React.isValidElement(post.flag.localFile) ? (
              post.flag.localFile
            ) : (
              <GatsbyImage
                image={
                  getImage(
                    fileToImageLikeData(post.flag.localFile),
                  ) as IGatsbyImageData
                }
                alt="Flag of the reviewer country | Codica"
                title="Flag of the reviewer country"
                loading="lazy"
              />
            )}
          </div>
        </div>
        <div className={containerStyles.testimonialCard__position}>
          {post.position}
        </div>
      </div>
    </div>
    {post.logo && (
      <div className={containerStyles.testimonialCard__logo}>
        {React.isValidElement(post.logo.localFile) ? (
          post.logo.localFile
        ) : (
          <GatsbyImage
            image={
              getImage(
                fileToImageLikeData(post.logo.localFile),
              ) as IGatsbyImageData
            }
            alt="Logo of the reviewer | Codica"
            title="Logo of the reviewer"
            loading="lazy"
          />
        )}
      </div>
    )}
  </div>
)

export default TestimonialReviewer
