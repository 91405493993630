import HeadPagesBlock from '~/components/blocks/HeadPagesBlock'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import { TestimonialsPageProps } from '~/pages/testimonials'
import TestimonialPost from '~/views/Testimonials/components/TestimonialPost'

const Testimonials = ({ data }: TestimonialsPageProps) => (
  <MainLayout scrollTop>
    <HeadPagesBlock
      title="Clients Reviews"
      description="Our clients’ success stories speak best about our work."
    />

    <TestimonialPost data={data} />

    <PrimeContactFormSection title="Let’s turn your idea into a successful product!" />
  </MainLayout>
)
export default Testimonials
