import ContactFormSection from '~/components/shared/ContactFormSection'
import { ContactFormSectionProps } from '~/components/shared/ContactFormSection/ContactFormSection.view'
import SubtractBlock from '~/components/shared/SubtractBlock'

import * as containerStyles from './PrimeContactFormSection.module.scss'

type PrimeContactFormSectionProps = Pick<
  ContactFormSectionProps,
  | 'title'
  | 'widthTitle'
  | 'formInfoClassName'
  | 'customSalesInfo'
  | 'customSalesInfoStepFour'
>

const PrimeContactFormSection = ({
  title,
  widthTitle,
  formInfoClassName,
  customSalesInfo,
  customSalesInfoStepFour,
}: PrimeContactFormSectionProps) => (
  <div className={containerStyles.primeFormSection}>
    <SubtractBlock isTop />
    <ContactFormSection
      title={title}
      widthTitle={widthTitle}
      formInfoClassName={formInfoClassName}
      customSalesInfo={customSalesInfo}
      customSalesInfoStepFour={customSalesInfoStepFour}
    />
  </div>
)

export default PrimeContactFormSection
